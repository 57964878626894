.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}
.strike>h4 {
    position: relative;
    display: inline-block;
}

.strike>h4:before,
.strike>h4:after {
    content: "xx";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 3px;
    background: red;
}

.strike>h4:before {
    right: 100%;
    margin-right: 15px;
}

.strike>h4:after {
    left: 100%;
    margin-left: 15px;
}


/* ------------- layout and positioning ------------- */
/* -------------------------------------------------- */
.travelred {
    color: #D41D3A;
}

.ppn_nav {
    padding: 25px;
    display: flex;
    background: #0A2D48;
}

.ppn_nav a {
    flex: 1;
    text-align: right;
    margin-top: 13px;
}

.ppn_nav a:first-child {
    width: 300px;
    text-align: left;
    margin-top: 0px;
}

.ppn_logo img:last-child {
    padding-left: 20px;
    /* margin-top:11px; */
}

.ppn_logo img:first-child {
    float: left;
    border-right: 1px solid #1C4F7C;
    padding-right: 20px;
    margin-top: 11px;
}

.ppn_header {
    background: white;
    padding: 45px 20px;
}

.ppn_header--small {
    padding: 25px 20px;
}

.ppn_header__container {
    margin:0px auto;
    max-width: 1080px;
    position: relative;
}

.ppn_styles i {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.ppn_header__container .ppn_heading.ppn_heading--medium {
    font-weight: 400;
    margin-bottom: 0px;
}

.ppn_header__container .ppn_heading.ppn_heading--xlarge {
    margin-bottom: 15px;
}

.ppn_main {
    margin: 80px 20px 40px 20px;
}

.ppn_section {
    margin:40px auto;
    max-width: 1100px;
    position: relative;
}

.ppn_section--small {
    max-width: 575px;
}

.ppn_section--withSplit {
    display: flex;
}

.ppn_branding {
    width: 465px;
    margin: 0px auto;
}

.ppn_section--withShadow {
    -webkit-box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
    box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
}

.ppn_card {
    /* padding: 60px 50px 45px 50px; */
    padding: 25px 35px 25px 35px;
    background: white;
}

.ppn_card--withBackgroundImage {
    color: white;
    background: #0D2C48;
}
.newClass {
    margin-bottom: 0 !important;
}
.ppn_card--withBackgroundImage .ppn_heading {
    color: white;
}

.ppn_card--left {
    width: 60%;
    flex: 1;
}

.ppn_card--right {
    width: 40%;
    flex: 1;
}

.ppn_hr {
    display: block;
    margin:40px 0px;
    background-color: #D2D9DE;
    height: 1px;
    border: 0;
}



@media screen and (max-width: 980px) {

    .ppn_card {
        padding: 40px 40px 25px 40px;
    }
}
 
@media screen and (max-width: 479px) {
    .ppn_section--withSplit.ppn_section--withShadow {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 900px) {

    .ppn_section--withSplit.ppn_section--withShadow {
        display: block;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .ppn_section--withSplit.ppn_section--withShadow .ppn_card {
        -webkit-box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
        -moz-box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
        box-shadow: 0px 0px 38px 3px rgba(0,0,0,0.10);
    }

    .ppn_card--left {
        width: auto;
        flex: none;
        margin-bottom: 20px;
    }

    .ppn_card--right {
        width: auto;
        flex: none;
    }
}

@media screen and (max-width: 600px) {

    .ppn_main {
        margin-top: 20px;
    }

    .ppn_card {
        padding: 30px 30px 15px 30px;
    }

    nav.ppn_nav {
        display: block;
    }

    nav.ppn_nav a:last-child {
        margin-left: 20px;
    }

    nav.ppn_logo img:last-child {
        margin-top: 0px;
    }
}

/* ----------------- List Styles -------------------- */
/* -------------------------------------------------- */

.ppn_styles ul {
    margin-left:0px;
    padding-left: 18px;
    line-height: 28px;
}

.ppn_styles .ppn_list--checked {
    list-style-type: none;
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
}


.ppn_styles .ppn_list--checked li {
    background: url('../icons/check.svg') no-repeat 0px 5px;
    padding-left: 33px;
}


.ppn_numbered {
    line-height: 22px;
    position: relative;
}

.ppn_numbered__item {
    margin-left: 11px;
    padding-left: 35px;
    padding-bottom: 25px;
}

.ppn_number {
    border: 3px solid #D61D37;
    display: inline-block;
    color: #D61D37;
    border-radius: 50%;
    font-size:13px;
    line-height: 20px;
    width: 25px;
    display: inline-block;
    text-align: center;
    position: absolute;
    left: 0px;
    background: white;
}

.ppn_styles .ppn_numbered__item ul {
    padding-top: 15px;
    margin-bottom: 0px;
}

.ppn_numbered .ppn_numbered__item {
    border-left: 3px solid #d61d37;
}

.ppn_numbered .ppn_numbered__item:last-child {
    border: none;
}

@media screen and (max-width: 400px) {

    .ppn_numbered .ppn_numbered__item {
        border:none;
    }

    .ppn_numbered__item {
        position: relative;
        margin-left: 0px;
        padding-left: 0px;
        padding-top: 40px;
    }

    .ppn_number {
        top: 0px;
    }
}

/* ----------------- Typography --------------------- */
/* -------------------------------------------------- */

.ppn_styles {
    font-family: 'Montserrat','Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
    color: #586977;
    font-size: 16px;
    background: #E7ECF2;
}

.ppn_styles sup {
    color: #D61D37;
}

.ppn_styles p,
.ppn_styles ul {
    /* margin: 0px 0px 25px 0px; */
    margin: 0px 0px 10px 0px;
    /* line-height: 25px; */
}

.ppn_align--center {
    text-align: center;
}

.ppn_align--right {
    text-align: right;
}

.ppn_align--justify{
    text-align: justify;
}
.ppn_heading {
    font-size: 16px;
    display: block;
    font-weight: 500;
    /* margin: 0px 0px 10px 0px; */
    color: #0e2d48;
}

.ppn_heading--caps {
    text-transform: uppercase;
    font-weight: 600;
}

.ppn_heading--xlarge {
    font-size: 30px;
}

.ppn_heading--large {
    font-size: 25px;
    margin: 0px 0px 35px 0px;
}

.ppn_heading--medium{
    font-size: 18px;
}

.ppn_type--small {
    font-size: 12px;
}

.ppn_type--link {
    color: #00468C;
}

@media screen and (max-width: 500px) {

    .ppn_heading--xlarge {
        font-size: 26px;
    }

    .ppn_heading--large {
        font-size: 22px;
    }
}

/* -------------------- Forms ----------------------- */
/* -------------------------------------------------- */

.ppn_fieldset {
    /* margin: 0px 0px 20px 0px; */
    margin: 0px 0px 22px 0px;
    display: block;
    padding:0px;
    border:0px;
}

.ppn_input {
    background-repeat: no-repeat;
    /* background-position: 10px 15px; */
    background-position: 10px 14px;
}

.ppn_input--card {
    background-image: url('../icons/card.svg');
}

.ppn_input--birthday {
    background-image: url('../icons/birthday.svg');
}

.ppn_input--user {
    background-image: url('../icons/user.svg');
}

.ppn_fieldset-group {
    display: flex;
    margin-left: -20px;
}

.ppn_fieldset-group .ppn_fieldset {
    flex: 1;
    margin-left: 20px;
}

.ppn_fieldset label {
    font-size: 12px;
    /* margin-bottom: 5px; */
    display: block;
}

.ppn_fieldset input {
    width: 100%;
    padding: 0px 15px 0px 45px; 
    border: 1px solid #D2D9DE;
    border-radius: 4px;
    box-sizing: border-box;
}

.ppn_fieldset--checkbox label {
    font-size: 12px; 
    /* display: inline; */
}

.ppn_fieldset--checkbox input {
    width: auto;
    margin-right: 15px;
    float: left;
    margin-top:4px;
}

.ppn_fieldset--withError label {
    color: #D41D3A;
}

.ppn_fieldset--withError input {
    border-color: #D41D3A;
}

.ppn_fieldset .ppn_button {
    width: 100%;
}

.ppn_form_section {
    position: relative;
}

.ppn_form_section__message {
    display: none;
}

.ppn_form_section--withErrors .ppn_form_section__message {
    border: 1px solid #D41D3A;
    color: #D41D3A;
    font-size: 13px;
    padding: 5px 5px;
    line-height: 16px;
    display: block;
}

.ppn_form_section .ppn_loading {
    /* display: none;*/
    /* position: absolute; */
    background: white;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
}

.ppn_form_section .ppn_loading__animation {
    padding-top: 43%;
    margin-top: -60px;
    margin-left: -10px;
    width: 110%;
    padding-bottom: 43%;
}

.ppn_form_section--isSending .ppn_loading {
    display: block;
}

@media screen and (max-width: 500px) {

    .ppn_fieldset-group {
        display: block;
        margin-left: 0px;
    }

    .ppn_fieldset-group .ppn_fieldset {
        flex: none;
        margin-left: 0px;
    }
}

/* -------------------- Buttons --------------------- */
/* -------------------------------------------------- */

.ppn_button {
    font-size: 16px;
    display: inline-block;
    padding: 15px 22px;
    text-decoration: none;
    background: #0A2D48;
    border: 1px solid white;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.ppn_button--large {
    padding: 6px 27px; 
    
}

.ppn_button--primary {
    background: #D41D3A;
}

.ppn_button--primary:hover {
    background: #C92242;
}

/* ---------------------- Modal --------------------- */
/* -------------------------------------------------- */

.ppn_modal {
    background: rgba(6,57,88,.95);
    padding: 20px 20px 20px 20px;
}

.ppn_modal--isOpen {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.ppn_modal .ppn_section:not(.ppn_section--small) {
    max-width: 710px;
}

.ppn_modal .ppn_heading--caps {
    color: #D71D3E;
    font-size: 18px;
}

.ppn_modal .ppn_heading--caps span {
    background: white;
    padding:0px 10px;
}

.ppn_modal .ppn_heading--caps + .ppn_hr {
    margin-top:-35px;
}

@media screen and (max-width: 500px) {
    .ppn_modal .ppn_heading--caps + .ppn_hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


/* -------------------- Loading animation --------------------- */
/* ------------------------------------------------------------ */

.sk-fading-circle {
  margin: 0px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #092D45;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}

